import React, { Component } from 'react';
import { withTranslation, Translation, } from 'react-i18next';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';

import { View, ActivityIndicator} from "react-native-web";

/*import HeaderPC from '../../header/HeaderPC';
import HeaderMobile from '../../header/HeaderMobile';
import HeaderTablet from '../../header/HeaderTablet';*/

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function LoadingPage() {




    return (

	         <div>


                  {!isMobileOnly && !isTablet &&
                    <BrowserView>

                      <View style={{width:viewportWidth, height: viewportHeight, position: 'relative', justifyContent: 'center', alignSelf: 'center', backgroundColor: '#111'}}>


                        <ActivityIndicator  animating={true} color="#f2f068" />
                      </View>
                   </BrowserView>
                 }



                 {isMobileOnly &&
           			  <MobileView>
                    <View style={{width:viewportWidth, height: viewportHeight, position: 'relative', justifyContent: 'center', alignSelf: 'center', backgroundColor: '#111'}}>



                      <ActivityIndicator  animating={true} color="#f2f068" />
                    </View>
           			 </MobileView>
           			}

           			{isTablet &&
           			  <TabletView>
                      <View style={{width:viewportWidth, height: viewportHeight, position: 'relative', justifyContent: 'center', alignSelf: 'center', backgroundColor: '#111'}}>





                        <ActivityIndicator  animating={true} color="#f2f068" />



                      </View>
           			  </TabletView>
           			}








	        </div>

    )

}
