import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client'
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { inject, observer, Provider } from "mobx-react";

import { sessionService, sessionReducer } from 'redux-react-session';

import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import configureStore from "./store";

import routes from "./routes";

import i18n from "./i18n";

import './index.css';

import App from './App';



const hist = createBrowserHistory();



export var store = configureStore();

// Init the session service
sessionService.initSessionService(store);




const stripePromise = loadStripe('pk_live_1NtV1g1sGnZEVL2BfkAxsSiu');

createRoot(document.getElementById('root')).render(<Elements stripe={stripePromise} options={{ locale:"fr" }}>
<Provider store={store} history={hist}>
 <App store={store} history={hist}/>
 </Provider>
  </Elements>)
