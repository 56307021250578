import React, { Component, useState, useEffect, useMemo, useRef } from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import loadable from "@loadable/component";

import {
  BrowserRouter,
  Routes,
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom';

import { withTranslation, Translation, } from 'react-i18next';




import LoadingPage from "./components/pages/home/LoadingPage";




//const algoliasearch = require('algoliasearch');
//var client = algoliasearch('HKB157K3KU', '357f2b37e5ef8c12f7b90877758ff663');


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


const HomePage = loadable(() => import("./components/pages/home/HomePage"), {
   fallback: <LoadingPage />
});


const AssetsPage = loadable(() => import("./components/pages/home/AssetsPage"), {
   fallback: <LoadingPage />
});

const NoMatch = loadable(() => import("./components/pages/home/NoMatch"), {
   fallback: <LoadingPage />
});


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <HomePage/>
    ),
  },
  {
    path: "about",
    element: <div>About</div>,
  },
]);

/*
<RouterProvider router={router} />
*/

const ProtectedRoute = ({ isSignedin, children }) => {

  if (!isSignedin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const PublicRoute = ({ isSignedin, children }) => {

  if (isSignedin) {
    return <Navigate to="/home" replace />;
  }

  return children;
};



const App = () => {




      useEffect(() => {





          fetch('https://api.ipstack.com/check?access_key=9cd4ffbf45a257de9e644d4d875a9465')
           		  	.then((response) => response.json())
           		  	.then((responseData) => {
           			  	   //alert("App. Store: " + JSON.stringify(responseData));

                       global.country = responseData.country_code;
                       global.continent = responseData.continent_code;
                       global.ip = responseData.ip;

           		      })
           		     .catch(function(error) {

           		     });



      }, []);







    //console.log('>> ---- sinUser >> ' + JSON.stringify(_authStatus));

       return (
         <Translation>
           {
               t =>
              <RecoilRoot>
               <BrowserRouter>

                 <Routes>
                   <Route path="/" element={<PublicRoute isSignedin={false}><HomePage /></PublicRoute>} />
                   <Route path="/assets" element={<PublicRoute isSignedin={false}><AssetsPage /></PublicRoute>} />
                   <Route path="*" element={<NoMatch />} />
                 </Routes>
               </BrowserRouter>



             </RecoilRoot>
            }
        </Translation>
      );

}

export default App;
