import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
		          "English": "English",
			   "French": "French",
                       "signin": "Sign in",
	               "signup": "Sign up",
	               "signout": "Sign out",
			account_settings: "Account Settings",
			Blocked_profiles: "Blocked Profiles",
			Payment_methods: "Payment methods",
			"Full_name": "Full name",
			"Password": "Password",
			"Forgot_password": "Forgot password?",
			sign_in_error: "Email or password is incorrect",
                     you_will_receive_an_email_with_instructions: "You will receive an email with instructions about how to reset your password in a few minutes",			
			A_code_has_been_sent: "A code has been sent to your email address. Please enter that code here.",
			A_code_has_been_sent_to_you: "A code has been sent to your phone number. Please enter that code here.",
			You_entered_an_incorrect_code: "You entered an incorrect code. Please check your code and try again",
			Continue: "Continue",
			Enter_code: "Enter code",
			Send_code_again: "Send code again",
			Create_a_new_password: "Create a new password",
			Type_a_new_password: "Type a new password",			
		       password_blank: "Password can't be blank.",
		       password_short: "Password is too short (min. is 6 char).",
		        name_blank: "Name can't be blank.",
		       email_blank: "Email can't be blank.",
		       email_taken: "Email has already been taken.",
		      email_not_found: "Email doesn't match our records.",
		      email_invalid: "Email is invalid.",
			resetpassword: "Reset password",
			reset: "Reset",			
			I_agree_to_debyoos: "I agree to Debyoo's",
			terms_short: "Terms of Use",
			and: "and",
			eula_short: "End-User Licence",
			Eulagreement: "End-User Licence Agreement",
			of_debyoo_fr: "",
			You_must_agree_to_the_terms: "You must agree to the Terms of Use and End-User Licence before signing up.",
			Welcome_to_Debyoo: "Welcome to Debyoo!",
			Change_profile_photo: "Change Profile Photo",
			Do_it_later: "I'll do it later",
			Continue: "Continue",
			Move_and_scale: "Move and Scale",
			Save: "Save",
			Cancel: "Cancel",
			"Home": "Home",
			 "Our_mission": "Our mission",
			  "Contact": "Contact",
			   "Contact_us": "Contact Us",
			"Privacy": "Privacy",
			"Terms": "Terms of use",
			  "Email": "Email",
			   "General_inquiries": "General inquiries",
			"Press": "Press",
			"Legal": "Legal",
			"Support": "Support",
			"Music_industry": "Music industry",
			"For_inquiries_related_to_song_pages": "For inquiries related to Song Pages",
			"Fashion_industry": "Fashion industry",
			"Debyoo_introduces_songpages": "Debyoo introduces Song Pages",
			   "Rethinking_songpage": "(Re)thinking music creation process, distribution and promotion.",
			  "Redefining_the_future_of_digital_music_marketing_and_distribution": "Redefining the future of digital music marketing and distribution.",
			  "No_ intermediaries_songpage": "Artist or independent Label? Create and publish Song Pages on Debyoo on your own, without intermediaries.",
			  "Simple_transparent_models": "Simple and transparent revenue model for artists, labels, songwriters and publishers.",	
			"congratulations_your_email_address_has_been_confirmed":  "Congratulations, your email address has been confirmed and your account is now activated",
			"New_story": "New Story",	
			"Africa": "Africa",
			 "Investor'": "Investor",
			Sorry_this_page_isnt_available: "Sorry, this page isn't available.",
			Page_doesnt_exist_no_more: "The page you requested was not found.", 
			Join_the_community: "Join the community",
			You_will_soon_be_able_to_join_this_community: "You will soon be able to join this community.",
			Im_a_dreamer: "I'm a dreamer",
			Im_an_initiator: "I'm an initiator",
			Im_an_elder: "I'm an elder",
	             A_dreamer: "A dreamer",
		     An_initiator: "An initiator",
		    An_elder: "An elder",
			
		  If_you_already_have_a_debyoo_profile: "If you already have a Debyoo profile",
		  If_you_dont_have_a_debyoo_profile: "If you don't have a Debyoo profile",
		  Signin_with_your_debyoo_account: "Sign in with your Debyoo account.",
		  And_then_follow_instructions: "And then follow the instructions set on the screen.",
		  Signup_first: "Sign up first.",
		  Powered_by: "Powered by",
		  Please_fill_this_form_and_click_request: "Please fill this form below and then click «Request an invitation».",
		  Please_fill_this_form_and_click_send_application: "Please fill this form below and then click «Send application».",
			
		I_want_to_join_the_community_as: "I want to join the community as",
			I_live: "I live",
			In_togo: "in Togo",
			Outside_togo: "outside Togo",
			My_whatsapp_contact: "My WhatsApp contact",
			optional: "optional",
			I_confirm: "I confirm",
			Cancel: "Cancel",
			
			Thank_you: "Thank you",
			 request_successfully_sent: "Request successfully sent.",
			we_will_contact_you_very_shortly: "We will contact you very shortly.",
			Hi: "Hi",
			We_already_received_request: "We have already received your request.",
			
			Your_password_has_been_successfully_changed: "Your password has been successfully changed",
			You_can_signin_with_your_new_password: "You can sign in with your new password",
			
			The_challenges: "The challenges",
			Why_youth: "Why youth?",
			Mission_and_vision: "Mission & Vision",
			Scope_of_action: "Scope of action",
			Were_on_social_media: "We're on social media and we'd love you to give us a follow!",
			You_can_catch_us_on: "You can catch us on",
			Follow_us_on_social_networks: "Nous suivre sur les réseaux sociaux",
			Local_yinitj: "Local Youth Initiative Jeunesse",
			Join: "Join",
			Select_a_country: "Select a country",
			Select: "Select",
			Not_available_yet: "Not available yet",
			No_options: "No options",
			Africa: "Africa",
			TG: "Togo",
			 CI: "Ivory Coast",
			GH: "Ghana",
			 SN: "Senegal",
			NG:  "Nigeria",
			America: "America",
			Asia_and_oceania: "Asia and Oceania",
			Europe: "Europe",
			
        }
      },
      fr: {
        translation: {
	          "English": "Anglais",
		   "French": "Français",
                 "signin": "Se connecter",
	         "signup": "S'enregistrer",
	         "signout": "Se déconnecter",
			account_settings: "Paramètres du compte",
			Blocked_profiles: "Profils Bloqués",
			Payment_methods: "Méthodes de paiement",
			"Full_name": "Nom complet",
			"Password": "Mot de passe",
			"Forgot_password": "Mot de passe oublié?",
			resetpassword: "Réinitialiser le mot de passe",
			reset: "Réinitialiser",                     
			A_code_has_been_sent: "Un code a été envoyé à votre adresse email. Veuillez saisir ce code ici.",
			A_code_has_been_sent_to_you: "Un code a été envoyé à votre numéro de téléphone. Veuillez saisir ce code ici.",
			You_entered_an_incorrect_code: "Vous avez saisi un code incorrect. Veuillez vérifier votre code et réessayez à nouveau",
			Continue: "Continuer",
			Enter_code: "Saisir le code",
			Send_code_again: "Renvoyez-moi encore le code",
			Create_a_new_password: "Créez un nouveau mot de passe",
			Type_a_new_password: "Nouveau mot de passe",	
			sign_in_error: "Email ou mot de passe incorrect",
                     email_not_found: "Nous ne retrouvons pas cet email.",
			you_will_receive_an_email_with_instructions: "Vous allez recevoir un email contenant les instructions pour réinitialiser votre mot de passe",						
		       password_blank: "Le mot de passe est requis.",
		       password_short: "Mot de passe trop court (min. 6 caract).",
		       name_blank: "Le nom est requis.",
		       email_blank: "L’email est requis.",
		       email_taken: "Cet email est déjà pris.",
		       email_not_found: "Nous ne retrouvons pas cet email.",
		       email_invalid: "Vérifiez le format de l’email.",
		       I_agree_to_debyoos: "J'accepte",
			terms_short: "les Conditions d'Utilisation",
			and: "et",
			eula_short: "la Licence de l'Utilisateur Final",
			Eulagreement: "Contrat de Licence Utilisateur Final",
			of_debyoo_fr: "de Debyoo",
			You_must_agree_to_the_terms: "Vous devez accepter les Conditions d'utilisation et la Licence de l'utilisateur final avant de vous enregistrer.",
			Welcome_to_Debyoo: "Bienvenue sur Debyoo!",
			Change_profile_photo: "Changer la Photo de Profil",
			Do_it_later: "Je le ferai plus tard",
			Continue: "Continuer",
			Move_and_scale: "Déplacer et Modifer l'échelle",
			Save: "Sauvegarder",
			Cancel: "Annuler",
			"Home": "Accueil",
		       "Our_mission": "Notre mission",
		     "Contact": "Contact",
		      "Contact_us": "Nous Contacter",
			"Privacy": "Vie privée",
			"Terms": "Termes d'utilisation",
		     "Email": "Email",
		      "General_inquiries": "Renseignements généraux",
			"Press": "Presse",
			"Legal": "Aspect juridique",
			"Support": "Assistance",
			"Music_industry": "Industrie de la musique",
			"For_inquiries_related_to_song_pages": "Pour les renseignements concernant les Song Pages",
			"Fashion_industry": "Industrie de la mode",
			"Debyoo_introduces_songpages": "Debyoo introduit les Song Pages",
		   "Rethinking_songpage": "(Ré)imaginer la création, la distribution et la promotion de la musique.",
		  "Redefining_the_future_of_digital_music_marketing_and_distribution": "Nous redéfinissons le futur de la distribution et du marketing digital de la musique.",
		   "No_ intermediaries_songpage": "Artiste ou Label indépendant? Créez et publiez vos Song Pages sur Debyoo sans passer par un intermédiaire.",
		  "Simple_transparent_models": "Modèle de revenus simple et transparent pour les artistes, labels, auteur-compositeurs et éditeurs.",	
		 "congratulations_your_email_address_has_been_confirmed": "Félicitations, votre adresse email a été confirmée et votre compte est à présent activé",
		  "New_story": "Nouvelle Story",
		  "Africa": "Afrique",
	        "Investor'": "Investisseur",
		  Sorry_this_page_isnt_available: "Désolé, cette page n'est pas disponible.",	
		  Page_doesnt_exist_no_more: "La page que vous cherchez n'existe pas, a été transférée ou n'existe plus.",	
		 Join_the_community: "Rejoignez la communauté",
		 You_will_soon_be_able_to_join_this_community: "Vous aurez bientôt la possibilité de rejoindre cette communauté.",
		Im_a_dreamer: "Je suis un rêveur",
		Im_an_initiator: "Je suis un initiateur",
		Im_an_elder: "Je suis un sage",
	        A_dreamer: "Un rêveur",
		An_initiator: "Un initiateur",
		An_elder: "Un sage",
			
		  If_you_already_have_a_debyoo_profile: "Si vous avez déjà un profil Debyoo",
		  If_you_dont_have_a_debyoo_profile: "Si vous n'avez pas encore un profil Debyoo",
		  Signin_with_your_debyoo_account: "Connectez-vous avec votre compte Debyoo.",
		  And_then_follow_instructions: "Et puis suivez les instructions qui s'affichent à l'écran.",
		  Signup_first: "Enregistrez-vous d'abord.",
		  Powered_by: "Alimenté et propulsé par",
		  Please_fill_this_form_and_click_request: "Veuillez remplir le formulaire ci-dessous et puis cliquez «Demandez une invitation».",
		  Please_fill_this_form_and_click_send_application: "Veuillez remplir le formulaire ci-dessous et puis cliquez «Envoyez votre demande».",	
			
		 I_want_to_join_the_community_as: "Je souhaite rejoindre la communauté en tant que",
			I_live: "Je réside",
			In_togo: "au Togo",
			Outside_togo: "à l'étranger",
			My_whatsapp_contact: "Mon contact WhatsApp",
			optional: "facultatif",
			I_confirm: "Je confirme",
			Cancel: "Annuler",
			
			Thank_you: "Merci",
			request_successfully_sent: "Demande envoyée avec succès.",
			 we_will_contact_you_very_shortly: "Nous vous contacterons très prochainement.",
			Hi: "Salut",
			We_already_received_request: "Nous avons déjà reçu votre demande.",
			
			Your_password_has_been_successfully_changed: "Votre mot de passe a été modifié avec succès",
			You_can_signin_with_your_new_password: "Vous pouvez vous connecter avec votre nouveau mot de passe",
			
			The_challenges: "Les défis",
			Why_youth: "La jeunesse",
			Mission_and_vision: "Mission & Vision",
			Scope_of_action: "Champ d'action",
			Were_on_social_media: "Nous sommes aussi sur les réseaux sociaux et vous pouvez déjà nous suivre.",
			You_can_catch_us_on: "Suivez-nous sur",
			Follow_us_on_social_networks: "Nous suivre sur les réseaux sociaux",
			Local_yinitj: "Youth Initiative Jeunesse locale",
			Join: "Réjoindre",
		    Select_a_country: "Sélectionnez un pays",
		   Select: "Sélectionner",	
		  Not_available_yet: "Pas disponible actuellement",
			No_options: "Aucune option",
		  Africa: "Afrique",
		   TG: "Togo",
		   CI: "Côte d'Ivoire",
		  GH: "Ghana",
		  SN: "Sénégal",
		   NG:  "Nigéria",
		  America: "Amérique",
		 Asia_and_oceania: "Asie et Océanie",
		 Europe: "Europe"
        }
      },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });
  
export default i18n;  
